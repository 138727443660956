import { css } from "@emotion/react"
import React, { useCallback } from "react"
import tw from "twin.macro"
import { useTealiumContext } from "../../../contexts/Tealium"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { TabTitleProps } from "./Tab.d"

const TabTitle: React.FC<TabTitleProps> = ({
  title,
  setActiveFamily,
  families,
  selected,
  index,
  analyticsId = null,
}) => {
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()
  const onClick = useCallback(() => {
    trackTealEvent({
      tealium_event: "refinement",
      refinement_type: `${families[index].family}`,
      refinement_value: `${tealPageData.page_type}|${tealPageData.page_name}|Vehicle Group|${families[index].family}`,
    })
    setActiveFamily(index)
  }, [setActiveFamily, index, tealPageData])
  const tabAnimation = css`
    &:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
    content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:hover:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const focusAnimation = css`
    &:focus-visible:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
    content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:focus-visible:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  return (
    <li css={[tw`mx-4`]}>
      <button
        onClick={onClick}
        role="tab"
        css={[
          tabAnimation,
          focusAnimation,
          tw`cursor-pointer whitespace-nowrap inline-block relative py-2 mx-2 border border-transparent`,
          tw`focus-visible:(border-gray-900 border-dashed border outline-none)`,
          selected && tw`font-semibold focus-visible:(border-transparent)`,
          selected &&
            css`
              &:after {
                transform: scaleX(1);
                ${tw`h-1 bg-red-400`}
              }
              &:hover:after {
                ${tw`h-1 bg-red-400`}
              }
              &:focus-visible:after {
                transform: scaleX(1);
                ${tw`h-1 bg-red-400`}
              }
            `,
        ]}
        analytics-id={analyticsId ? `${analyticsId}:${index + 1}` : null}
      >
        {title}
      </button>
    </li>
  )
}

export default TabTitle
