import { css } from "@emotion/react"
import { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { LanguageContext } from "../../../contexts/Language"
import { useTealiumContext } from "../../../contexts/Tealium"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import Coupons from "./Coupons"
import { Coupon } from "./Coupons.d"
import moment from "moment"

const CouponsSection: React.FC<{ coupons: Coupon[] }> = ({ coupons }) => {
  const today = moment()
  const activeCoupons = coupons
    ?.map((coupon: Coupon) => {
      const endDate = coupon.expires && moment(coupon.expires).endOf("day")
      const startDate = coupon.starts && moment(coupon.starts).startOf("day")
      if (today.isBetween(startDate, endDate)) {
        return coupon
      } else {
        return null
      }
    })
    ?.filter(coupon => coupon)

  const [filteredCoupons, setFilteredCoupons] = useState(activeCoupons)
  const [selectedFilter, setSelectedFilter] = useState("all")
  const { _ } = useContext(LanguageContext)
  const couponCategories = ["All", "Parts", "Service"]

  const tabAnimation = css`
    &:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
      content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:hover:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const focusAnimation = css`
    &:focus-visible:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
      content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:focus-visible:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const active = css`
    ${tw`font-semibold focus-visible:(border-transparent)`}
    &:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
    &:hover:after {
      ${tw`h-1 bg-red-400`}
    }
    &:focus-visible:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
  `
  const [catData, setCatData] = useState(0)

  //Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()

  const filterCoupons = (category: string) => {
    setSelectedFilter(category.toLowerCase())
    if (category.toLowerCase() === "all" || category.toLowerCase() === "todo") {
      setFilteredCoupons(activeCoupons)
      trackTealEvent({
        tealium_event: "refinement",
        refinement_value: `${tealPageData?.page_type}|${tealPageData?.page_name}|Coupon Type|${category}`,
      })
    } else {
      setFilteredCoupons(
        activeCoupons?.filter(
          (coupon: any) =>
            coupon.category.toLowerCase() === category.toLowerCase()
        )
      )
      trackTealEvent({
        tealium_event: "refinement",
        refinement_value: `${tealPageData?.page_type}|${tealPageData?.page_name}|Coupon Type|${category}`,
      })
    }
  }

  return (
    <section aria-label="Coupon Section">
      <ul
        css={[
          tw`flex gap-6 overflow-x-scroll w-full px-4 my-2 scrollbar-hide py-1.5`,
          tw`md:(justify-center)`,
        ]}
      >
        {couponCategories?.map((cat, index) => (
          <button
            onClick={() => filterCoupons(cat)}
            aria-selected={selectedFilter === cat.toLocaleLowerCase()}
            role="tab"
            css={[
              tabAnimation,
              focusAnimation,
              tw`cursor-pointer whitespace-nowrap inline-block relative py-2 mx-2`,
              selectedFilter === cat.toLocaleLowerCase() && active,
            ]}
            analytics-id={`coupon filter:coupon:${index + 1}`}
          >
            {/* {_(cat)} */}
            {_(cat)} {_("Coupons")}
          </button>
        ))}
      </ul>
      <div css={[tw`max-w-7xl text-xs mx-auto px-8`]}>
        <div css={[tw`border-b pb-2`]}>
          <span css={[tw`font-semibold`]}> {filteredCoupons?.length} </span>
          {filteredCoupons?.length > 1 ? _("Coupons") : _("Coupon")}
        </div>
      </div>
      <Coupons
        coupons={filteredCoupons}
        showTitle={false}
        vertical={true}
        css={tw`relative z-50`}
      />
    </section>
  )
}

export default CouponsSection
