import React from "react"
import tw from "twin.macro"
import { TabsProps } from "./Tab.d"
import TabTitle from "./TabTitle"

const Tabs: React.FC<TabsProps> = ({
  families,
  activeFamily,
  setActiveFamily,
  children,
  analyticsId = null,
}) => {
  return (
    <article css={[tw`w-full pt-2 col-span-3 bg-gradient-to-b from-gray-100`]}>
      <ul
        css={[
          tw`flex justify-start w-full overflow-x-scroll scrollbar-hide`,
          tw`md:(flex justify-center pb-0 pt-3 w-full)`,
        ]}
      >
        {children?.map((item: { props: { title: string } }, index: number) => (
          <TabTitle
            key={index}
            title={item?.props?.title}
            index={index}
            setActiveFamily={setActiveFamily}
            selected={activeFamily === index}
            families={families}
            analyticsId={analyticsId}
          />
        ))}
      </ul>
      {children[activeFamily]}
    </article>
  )
}

export default Tabs
