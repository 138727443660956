import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../../contexts/Tealium"
import { css } from "@emotion/react"
import Icon from "../../../atoms/Icon"
import { ThreeArrowAnimation } from "../../../molecules/ThreeArrowAnimation"
import { OffersTabData } from "../OffersTab/OffersTab.d"
import { Image } from "../../../atoms/Image"
import { parseDisclaimerBlocks, toUsd } from "../../../../helpers"
import { useContext } from "react"
import { LanguageContext } from "../../../../contexts/Language"
import { FeaturedOffersData } from "../FeaturedOffers/FeaturedOffers.d"
import tw from "twin.macro"
import { TabProps, Tabs } from "../../../atoms/Tabs"

const FamilySeriesTabs: React.FC<any> = ({
  families,
  filtered,
  setFiltered,
  activeFamily,
  setActiveFamily,
}) => {
  // Tracks event:  Model refinement click
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()
  const { _ } = useContext(LanguageContext)
  const [{}, disclaimersDispatch] = useContext(DisclaimersContext)

  function scrollToOffersSection() {
    // Get the "offersSection" element
    const offers = document?.getElementById("offersSection")

    // Check if the screen width is below the mobile breakpoint
    const mobileBreakpoint = 769
    const isMobile = window.innerWidth < mobileBreakpoint

    // If the screen is on a mobile device, scroll to the "offersSection"
    if (isMobile && offers) {
      offers.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <Tabs
      activeFamily={activeFamily}
      setActiveFamily={setActiveFamily}
      families={families}
      analyticsId={"vehicle group selector:refinement"}
    >
      {families?.map((tab: OffersTabData) => (
        <Tab title={_(tab?.family)} css={[tw`!gap-0`]}>
          {tab?.data?.map((node: FeaturedOffersData, id: number) => {
            if (!node?.name || !node?.image || !node?.msrp) return null

            const selected = filtered.some(
              (e: { name: string }) => e.name === node?.name
            ) // Controls active styling

            /* Filter select/unselect */
            const onChange = (e: {
              target: { dataset: { id: number }; checked: any }
            }) => {
              const item = tab.data[e.target.dataset.id] // Bind selected vehicle to item
              let updatedState = filtered.filter(
                (node: { name: string }) => node.name !== item.name
              ) // Return all vehicles besides selected vehicle (if unselecting, this is where it's removed)
              if (e.target.checked) updatedState.unshift(item) // If selected vehicle is checked, add to state (if unselecting, this is where it's removed)
              setFiltered(updatedState) // Update local state
            }

            return (
              <div
                className="group"
                css={[
                  tw`flex flex-col items-center text-center relative pb-3 rounded-2xl transition-all duration-300 ease-out`,
                  //tw`lg:hover:(bg-gray-50)`,
                  tw`focus-visible:(outline-gray)`,
                  selected && tw`bg-gray-50`,
                ]}
                key={node?.key}
                aria-selected={selected === true ? "true" : "false"}
                analytics-id={`model selector:refinement:${id + 1}`}
              >
                <Image
                  imageData={node?.image}
                  css={[
                    tw`max-w-none mb-2 w-[150px]`,
                    tw`md:(h-[80px] w-auto)`,
                  ]}
                />
                <div css={[tw`font-semibold mx-3 relative`]}>
                  {node?.name}
                  <ThreeArrowAnimation selected={selected} />
                </div>
                <div>
                  <div css={["line-height: 0.875"]}>
                    <span>$</span>
                    {toUsd(node?.msrp)}
                    <span css={tw`relative group-hover:z-50`}>
                      {parseDisclaimerBlocks(`[starting_msrp]`, code =>
                        disclaimersDispatch(toggleDisclaimersModal(code))
                      )}
                    </span>
                  </div>
                  <div css={[tw`italic`, "font-size: 0.625rem"]}>
                    {_("Starting MSRP")}
                  </div>
                </div>
                <input
                  type="checkbox"
                  data-id={id}
                  onChange={onChange}
                  onClick={() => {
                    trackTealEvent({
                      tealium_event: "refinement",
                      refinement_value: `${tealPageData?.page_type}|${tealPageData?.page_name}|Vehicle Model|${node?.name}`,
                    })
                    // Scroll to the "offersSection" if on a mobile device
                    scrollToOffersSection()
                  }}
                  checked={selected}
                  css={[
                    tw`absolute top-0 left-0 w-full h-full cursor-pointer`,
                    css`
                      -webkit-appearance: none;
                      -moz-appearance: none;
                      -ms-appearance: none;
                      -o-appearance: none;
                      appearance: none;
                    `,
                    tw`focus-visible:(outline-gray opacity-100)`,
                  ]}
                />
              </div>
            )
          })}
        </Tab>
      ))}
    </Tabs>
  )
}

/* TAB CONTENT */
const Tab: React.FC<TabProps> = ({ children }) => {
  return (
    <div
      css={[tw`flex justify-center items-end flex-wrap py-5 scrollbar-hide`]}
    >
      {children}
    </div>
  )
}

export default FamilySeriesTabs
