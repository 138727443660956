import React, { useContext } from "react"
import tw from "twin.macro"
import { Image } from "../../../atoms/Image"
import { parseDisclaimerBlocks, toUsd } from "../../../../helpers"
import VehicleOffersGroup from "../VehicleOffersGroup/VehicleOffersGroup"
import { FeaturedOffersData, FeaturedOffersProps } from "./FeaturedOffers.d"
import { VehicleOffersGroupProps } from "../VehicleOffersGroup/VehicleOffersGroup.d"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../../contexts/Tealium"
import { scrollIntoView } from "seamless-scroll-polyfill"
import { css } from "@emotion/react"
import Icon from "../../../atoms/Icon"
import { ThreeArrowAnimation } from "../../../molecules/ThreeArrowAnimation"
import { LanguageContext } from "../../../../contexts/Language"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"

/**
 * Featured Offers tab on the /offers/ page
 * @author Tyler
 * @param {Object} featuredOffersData Trimmed data structure for this component
 * @param {Object[]} seriesYears Two most recent series years in the pool of offers
 * @param {Object} filtered Filtered state
 * @param {any} setFiltered For setting filtered state
 * @returns {React.FC} <FeaturedOffers />
 */

const FeaturedOffers: React.FC<FeaturedOffersProps> = ({
  featuredOffersData,
  seriesYears,
  filtered,
  setFiltered,
}) => {
  /* Tealium */
  //  Tracks event:  Model refinement click
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()
  const { _, language } = useContext(LanguageContext)
  const [{}, disclaimersDispatch] = useContext(DisclaimersContext)

  /* Filter select/unselect */
  const onChange = (e: {
    target: { dataset: { id: number }; checked: any }
  }) => {
    const item = featuredOffersData[e.target.dataset.id] // Bind selected vehicle to item
    let updatedState = filtered.filter(
      (node: { name: string }) => node.name !== item.name
    ) // Return all vehicles besides selected vehicle (if unselecting, this is where it's removed)
    if (e.target.checked) updatedState.unshift(item) // If selected vehicle is checked, add to state (if unselecting, this is where it's removed)
    setFiltered(updatedState) // Update local state
  }

  function scrollToOffersSection() {
    // Get the "offersSection" element
    const offers = document?.getElementById("offersSection")

    // Check if the screen width is below the mobile breakpoint
    const mobileBreakpoint = 769
    const isMobile = window.innerWidth < mobileBreakpoint

    // If the screen is on a mobile device, scroll to the "offersSection"
    if (isMobile && offers) {
      offers.scrollIntoView({ behavior: "smooth" })
    }
  }

  /* Dynamic offers count */
  const offerCounts =
    filtered.length === 0
      ? featuredOffersData // If no filters selected, render total offers count
          .map((node: FeaturedOffersData) => {
            if (node?.offers?.availableOffers) {
              return node.offers.availableOffers
            } else {
              return null
            }
          })
          ?.filter(availableOffersCount => availableOffersCount)
      : // Pull all selected offers lengths then return the sum
        filtered
          .map((node: FeaturedOffersData) => {
            if (node?.offers?.availableOffers) {
              return node.offers.availableOffers
            } else {
              return null
            }
          })
          ?.filter(availableOffersCount => availableOffersCount)

  const offersCount =
    offerCounts?.length > 0 ? offerCounts?.reduce((a, b) => a + b) : 0

  return (
    <>
      <section
        css={[tw`bg-gradient-to-b from-gray-100`]}
        aria-label="Featured Offers Model Selector"
      >
        {/* Filter options */}
        <div
          css={[
            tw`flex justify-center items-end flex-wrap py-5 scrollbar-hide`,
            tw`lg:(justify-center)`,
          ]}
        >
          {featuredOffersData.map((node: FeaturedOffersData, id: number) => {
            // Controls active styling
            const selected = filtered.some(
              (e: { name: string }) => e.name === node.name
            )

            return (
              <div
                className="group"
                css={[
                  tw`flex flex-col items-center text-center relative pb-3 rounded-2xl border border-transparent`,
                  // tw`lg:hover:(bg-gray-50)`,
                  tw`focus-visible:(outline-gray)`,
                  selected && tw`bg-gray-50`,
                ]}
                key={node?.key}
                aria-selected={selected === true ? "true" : "false"}
                analytics-id={`model selector:refinement:${id + 1}`}
              >
                <Image
                  imageData={node.image}
                  css={[tw`max-w-none mb-2`, "height: 80px"]}
                />
                <div css={[tw`relative font-semibold mx-3`]}>
                  {node.name}
                  <ThreeArrowAnimation selected={selected} />
                </div>
                <div>
                  <div css={["line-height: 0.875"]}>
                    <span>$</span>
                    {toUsd(node.msrp)}
                    <span css={tw`relative group-hover:z-50`}>
                      {parseDisclaimerBlocks(`[starting_msrp]`, code =>
                        disclaimersDispatch(toggleDisclaimersModal(code))
                      )}
                    </span>
                  </div>
                  <div css={[tw`italic`, "font-size: 0.625rem"]}>
                    {_("Starting MSRP")}
                  </div>
                </div>
                <input
                  type="checkbox"
                  data-id={id}
                  onChange={onChange}
                  onClick={() => {
                    trackTealEvent({
                      tealium_event: "refinement",
                      refinement_value: `${tealPageData?.page_type}|${tealPageData?.page_name}|Vehicle Model|${node?.name}`,
                    })
                    // Scroll to the "offersSection" if on a mobile device
                    scrollToOffersSection()
                  }}
                  css={[
                    tw`absolute top-0 left-0 w-full h-full cursor-pointer`,
                    css`
                      -webkit-appearance: none;
                      -moz-appearance: none;
                      -ms-appearance: none;
                      -o-appearance: none;
                      appearance: none;
                    `,
                    tw`focus-visible:(outline-gray)`,
                  ]}
                />
              </div>
            )
          })}
        </div>
      </section>
      <section
        css={[tw`flex flex-col w-full items-center px-8 pb-14 overflow-hidden`]}
        aria-label="Featured Offers Section"
      >
        <div
          css={[
            tw`w-full max-w-[70%] pt-11 pb-4 lg:(max-w-[50%]) xl:(max-w-7xl)`,
          ]}
          id="offersSection"
        >
          <span css={[tw`font-semibold`]}>{offersCount} </span>
          {language === "en" && " Toyota "}
          {_("Offers")}
          {language === "es" && " Toyota "}
        </div>
        {/* If no filters selected, render all vehicles */}
        {filtered.length === 0
          ? featuredOffersData.map(
              (node: VehicleOffersGroupProps, i: number) => (
                <VehicleOffersGroup
                  key={node?.key}
                  seriesYears={seriesYears}
                  {...node}
                  index={i}
                />
              )
            )
          : filtered.map((node: VehicleOffersGroupProps, i: number) => (
              <VehicleOffersGroup
                key={node?.key}
                filtered={filtered}
                seriesYears={seriesYears}
                {...node}
                index={i}
              />
            ))}
      </section>
    </>
  )
}

export default FeaturedOffers
