import React, {
  useCallback,
  useEffect,
  useContext,
  useState,
  useRef,
} from "react"
import { graphql } from "gatsby"
import tw from "twin.macro"
import { Layout } from "../components/atoms/Layout"
import FeaturedOffers from "../components/organisms/OffersPage/FeaturedOffers/FeaturedOffers"
import OffersTab from "../components/organisms/OffersPage/OffersTab/OffersTab"
import { Family, imageWithAlt, Model, Route, Series } from "../global"
import { Offer } from "../global"
import { createOfferGroupByYear, reduceModelGroups } from "../helpers"
import { SEO } from "../components/atoms/SEO"
import { HeroCarousel } from "../components/organisms/OffersPage/HeroCarousel"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import useTealiumEvent from "../hooks/Tealium/useTealiumEvent"
import { css } from "@emotion/react"
import { useTealiumContext } from "../contexts/Tealium"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { LanguageContext } from "../contexts/Language"
import useOffersData from "../hooks/useOffersData"
import CouponsSection from "../components/molecules/Coupons/CouponSection"
import { Coupon } from "../components/molecules/Coupons/Coupons.d"
import { motion } from "framer-motion"
import { DealersNearYou } from "../components/organisms/DealersNearYou"
import moment from "moment"

/**
 * Offers singleton page with featured offers
 * @author Tyler
 *
 */

const OffersPage: React.FC<OffersPageProps> = ({ data, location }) => {
  /**
   * ~~~~~ BINDINGS AND STATE
   *
   * The "filtered" state begins as an empty array, and when
   * the user selects a vehicle/series, that vehicle's data is placed at the
   * beginning of said array. The "filtered" array is mapped over and its data
   * is passed to and consumed by VehicleOffersGroup. When selectedTab changes,
   * i.e. one of the "... Offers" tabs is selected, "filtered" is emptied. When
   * "filtered" is empty, all vehicles are displayed. Therefore, when the user
   * selects a new "... Offers" tab, the filter is reset.
   */

  const { _ } = useContext(LanguageContext)
  const today = moment()
  const activeCoupons = data?.page?.coupons?.coupons
    ?.map((coupon: Coupon) => {
      const endDate = coupon?.expires && moment(coupon?.expires).endOf("day")
      const startDate = coupon?.starts && moment(coupon?.starts).startOf("day")
      if (today.isBetween(startDate, endDate)) {
        return coupon
      } else {
        return null
      }
    })
    ?.filter(coupon => coupon)

  const { tealPageData } = useTealiumContext()
  const now = moment()

  // Tealium View and Event functions

  // Events tracked include:
  // Offer refinement (Offers Types ("Featured Offers, Finance Offers..."))
  const { trackTealEvent } = useTealiumEvent()
  const { handleTealView } = useTealiumView()
  // Page data from GraphQL
  const { page } = data

  // Desctructure page data
  const { vehicleFamilies, featuredVehicles, hero, title } = data.page

  // Destructure hero data
  const { carouselItems, _type: heroType } = hero

  // Offers data pulled in from OffersContext
  const offers = useOffersData()
  const groupedOfferData = data?.offers?.group?.map(offersGroupedBySeries => {
    let offerCount = 0
    const offersGroupedByYearData = offersGroupedBySeries?.group?.map(
      offersGroupedByYear => {
        const validOffers = offersGroupedByYear?.nodes
          ?.map(offer => {
            const endDate = moment(offer?.endDate)?.endOf("day")
            const today = moment()?.startOf("day")
            const isExpired = endDate?.isSameOrBefore(today)

            if (isExpired) return null
            return {
              ...offer,
              isExpired: isExpired,
              isExpiringSoon: moment(offer?.endDate)?.diff(now, "days") <= 7,
            }
          })
          ?.filter(offer => offer)
        offerCount += validOffers.length
        return {
          fieldValue: offersGroupedByYear.fieldValue,
          nodes: validOffers,
        }
      }
    )
    return {
      fieldValue: offersGroupedBySeries.fieldValue,
      totalCount: offerCount,
      group: offersGroupedByYearData,
    }
  })

  // Filtered state, initialized as an array
  const [filtered, setFiltered] = useState([])

  // Offers tab state
  const [selectedTab, setSelectedTab] = useState("Featured")
  const [selectedTabTitle, setSelectedTabTitle] = useState("Default Title")

  // Reset filtered state when new offers tab is selected
  useEffect(() => {
    setFiltered([])
  }, [selectedTab])

  // Ascertain which series years the offers are for, store unique values and pull top 2
  const seriesYears = [
    ...new Set(offers?.map((node: Offer) => node?.series?.currentYear)),
  ].slice(0, 2)

  /**
   * ~~~~~ FEATURED DATA STRUCTURE
   *
   * Create an object for each featured series with featured model specs and
   * associated offers and store them all in an array we can iterate over.
   */
  const featuredOffersData = featuredVehicles
    // Pull featured model
    ?.map(
      (node: { models: Model[] }) =>
        // Break down model groups into individual models with reduceModelGroups helper, then find the object with isFeatured set to true
        // If no object with isFeatured set to true is found (because it's not set in the CMS), return the first object in the array
        reduceModelGroups(node?.models).find(
          (node: { isFeatured: boolean }) => node?.isFeatured === true
        ) ?? reduceModelGroups(node?.models)[0]
    )
    // Determine which offers we need and store them in the returned object
    ?.map((node: featuredOffersModel) => {
      const seriesData = node?.model?.series
      // Match offers to series
      const combinedSeriesOffers = groupedOfferData?.filter(
        (offerGroup: any) =>
          offerGroup?.fieldValue?.toLowerCase() ===
            seriesData?.name?.toLowerCase() + " hybrid" ||
          offerGroup?.fieldValue?.toLowerCase() ===
            seriesData?.name?.toLowerCase()
      )
      // if offers are found on model and hybrid models, combine
      const seriesOffers =
        combinedSeriesOffers.length > 1
          ? {
              fieldValue: combinedSeriesOffers[0].fieldValue,
              totalCount:
                combinedSeriesOffers[0].totalCount +
                combinedSeriesOffers[1].totalCount,
              group: createOfferGroupByYear(combinedSeriesOffers),
            }
          : combinedSeriesOffers[0]
      const offersMatchedToSeries = seriesOffers?.group?.sort(
        (a: any, b: any) => Number(b?.fieldValue) - Number(a?.fieldValue)
      )
      // Pull newest offers
      const newestOffers = offersMatchedToSeries
        ? offersMatchedToSeries[0]?.nodes
        : null

      // Pull second-newest offers
      const secondNewestOffers = offersMatchedToSeries
        ? offersMatchedToSeries[1]?.nodes
        : null

      // Determine featured offers -- this can be changed later to target more specific offers
      const pullFeatured = (e: Offer[]) => {
        let firstOffer = e?.find((node: Offer) => node?.cardLabel === "APR")
        let secondOffer = e?.find((node: Offer) => node?.cardLabel === "Lease")
        if (firstOffer === undefined)
          firstOffer = e?.find((node: Offer) => node?.cardLabel === "Cash")
        if (secondOffer === undefined)
          secondOffer = e?.find((node: Offer) => node?.cardLabel === "Cash")
        return [firstOffer, secondOffer]
      }

      const featuredNewestOffers = pullFeatured(newestOffers)
      const featuredSecondNewestOffers = pullFeatured(secondNewestOffers)

      // Featured image
      let featuredImage = null
      for (let i = 0; i < vehicleFamilies.familyRef.length; i++) {
        if (vehicleFamilies.familyRef[i].isActive) {
          featuredImage = vehicleFamilies.familyRef[i].routes?.find(
            (route: Route) => {
              return route.route.slug.current === seriesData.slug
            }
          )?.seriesImage
          if (featuredImage) {
            break
          }
        }
      }

      featuredImage =
        featuredImage?.image || node?.model?.jellybean?.asset?.gatsbyImageData

      return {
        key: node?.model?._id,
        image: featuredImage,
        name: seriesData?.name,
        slug: seriesData?.name,
        msrp: seriesData?.msrp,
        mpgCity: seriesData?.mpgCity,
        mpgHighway: seriesData?.mpgHighway,
        offers: {
          newest: featuredNewestOffers,
          newestYear: offersMatchedToSeries
            ? offersMatchedToSeries[0]?.fieldValue
            : null,
          secondNewest: featuredSecondNewestOffers,
          secondNewestYear: offersMatchedToSeries
            ? offersMatchedToSeries[1]?.fieldValue
            : null,
          availableOffers: seriesOffers?.totalCount || 0,
        },
      } as featuredOffers
    })

  /**
   * ~~~~~ FINANCE/LEASE/CASH DATA STRUCTURE
   *
   * Takes the data returned by the GraphQL query to Sanity, manipulates that data into
   * what OffersTab, and stores only that data in "offersData".
   */

  const offersData = vehicleFamilies?.familyRef
    ?.filter((family: Family) => family?.isActive === true)
    .map((family: Family) => {
      if (family)
        return {
          family: family?.name,
          data: family?.routes?.map(({ route, seriesImage }: Route) => {
            const vehicle = route?.page?.vehicle

            let modelData = reduceModelGroups(vehicle?.models)?.find(
              (model: { isFeatured: boolean }) => model?.isFeatured === true
            )?.model

            // if no featured model is found, return the first model in the array
            // there seems to be some issue with the reduceModelGroups helper
            // if any of the models have featured set to false but another is true it isn't getting the true one
            // this is problematic because it won't actually use the featured one
            // the fix below will make sure that we prevent $Nan from being displayed
            if (modelData === undefined) {
              if (vehicle?.models && vehicle?.models[0]?.models) {
                // this means we are dealing with a series that exclusively has models in a group
                modelData = vehicle?.models[0]?.models[0]?.model // TODO clean this up a bit but will work for current use case
              } else {
                return
              }
            }

            // Match offers to series
            const combinedSeriesOffers = groupedOfferData?.filter(
              (offerGroup: any) =>
                offerGroup?.fieldValue?.toLowerCase() ===
                  modelData?.series?.name?.toLowerCase() + " hybrid" ||
                offerGroup?.fieldValue?.toLowerCase() ===
                  modelData?.series?.name?.toLowerCase()
            )
            // if offers are found on model and hybrid models, combine

            const seriesOffers =
              combinedSeriesOffers.length > 1
                ? {
                    fieldValue: combinedSeriesOffers[0].fieldValue,
                    totalCount:
                      combinedSeriesOffers[0].totalCount +
                      combinedSeriesOffers[1].totalCount,
                    group: createOfferGroupByYear(combinedSeriesOffers),
                  }
                : combinedSeriesOffers[0]

            const offersMatchedToSeries = seriesOffers?.group?.sort(
              (a: any, b: any) => Number(b?.fieldValue) - Number(a?.fieldValue)
            )

            // Pull newest offers
            const newestOffers = offersMatchedToSeries
              ? offersMatchedToSeries[0]?.nodes?.filter((offer: Offer) => {
                  // Treating "TFS Lease Cash" as a "Lease" offer type
                  if (offer?.type === "TFS Lease Cash") {
                    return selectedTab === "Lease"
                  }
                  // Treating "TFS Finance Cash" as a "Finance" offer type
                  if (offer?.type === "TFS Finance Cash") {
                    return selectedTab === "APR"
                  }
                  return offer.cardLabel === selectedTab
                })
              : null

            // Pull second-newest offers
            const secondNewestOffers = offersMatchedToSeries
              ? offersMatchedToSeries[1]?.nodes?.filter((offer: Offer) => {
                  // Treating "TFS Lease Cash" as a "Lease" offer type
                  if (offer?.type === "TFS Lease Cash") {
                    return selectedTab === "Lease"
                  }
                  // Treating "TFS Finance Cash" as a "Lease" offer type
                  if (offer?.type === "TFS Finance Cash") {
                    return selectedTab === "APR"
                  }
                  return offer.cardLabel === selectedTab
                })
              : null

            return {
              key: modelData?._id,
              image: seriesImage?.image,
              name: modelData?.series?.name,
              slug: modelData?.series?.slug,
              msrp: modelData?.series?.msrp,
              mpgCity: modelData?.series?.mpgCity,
              mpgHighway: modelData?.series?.mpgHighway,
              offers: {
                newest: newestOffers,
                newestYear: offersMatchedToSeries
                  ? offersMatchedToSeries[0]?.fieldValue
                  : null,
                secondNewest: secondNewestOffers,
                secondNewestYear: offersMatchedToSeries
                  ? offersMatchedToSeries[1]?.fieldValue
                  : null,
                availableOffers: seriesOffers?.totalCount,
              },
            }
          }),
        }
      else return null
    })

  const offerTabs = [
    { title: _("Featured Offers"), type: "Featured" },
    { title: _("Finance Offers"), type: "APR" },
    { title: _("Lease Offers"), type: "Lease" },
    { title: _("Cash + Special Offers"), type: "Cash" },
    { title: _("Parts & Service Coupons"), type: "Coupons" },
  ]

  useEffect(() => {
    handleTealView({
      page_name: "offers full list",
      tealium_event: "offers_main",
      page_type: "offers",
    })
  }, [])

  /**
   * ~~~~~ MAIN BODY
   *
   * Top row of "... Offers" tabs is generated by mapping over the above "offerTabs"
   * object and passing each item to a Link comp. Link comp onClick changes which offer
   * types are filtered for in "offersData" above, as well as which vehicles/series menu
   * is rendered as the "Featured Offers" tab displays only the specified 5 whereas the
   * remaining three tabs display all families with all corresponding series. The useCallback
   * hook is used to mitigate re-renders.
   */
  const tabAnimation = css`
    &:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
     content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:hover:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const focusAnimation = css`
    &:focus-visible:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
     content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:focus-visible:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const active = css`
    ${tw`font-semibold focus-visible:(border-transparent)`}
    &:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
    &:hover:after {
      ${tw`h-1 bg-red-400`}
    }
    &:focus-visible:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
  `

  return (
    <Layout>
      <SEO
        url={location.href}
        title={title}
        keywords={page.seo?.keywords}
        schema={page.seo?.schema}
        description={page.seo?.description}
        canonicalPath={page.seo?.canonicalPath}
      />
      <section css={[tw`w-full relative z-50`]}>
        <HeroCarousel slides={carouselItems} offers={offers} />
      </section>
      <section
        css={[tw`flex flex-col items-center`]}
        aria-label="Offers Section"
      >
        <nav css={[tw`w-full relative z-10`]} aria-label="Offers Navigation">
          <ul
            css={[
              tw`flex gap-8 w-full px-11 py-10 bg-gray-100 text-center overflow-x-scroll scrollbar-hide lg:justify-center`,
            ]}
          >
            {offerTabs.map(
              (node: { title: string; type: string }, index: number) => {
                return (
                  <li key={node?.type}>
                    <button
                      onClick={() => {
                        setSelectedTab(node?.type)
                        setSelectedTabTitle(node?.title)
                        trackTealEvent({
                          tealium_event: "refinement",
                          refinement_type: node?.title || "",
                          refinement_value: `${tealPageData.page_type}|${tealPageData.page_name}|Offer Type|${node?.title}`,
                        })
                      }}
                      css={[
                        tabAnimation,
                        focusAnimation,
                        tw`cursor-pointer whitespace-nowrap inline-block relative py-2 mx-2`,
                        selectedTab === node?.type && active,
                      ]}
                      analytics-id={`offer type selector:refinement:${
                        index + 1
                      }`}
                    >
                      {node?.title}
                    </button>
                  </li>
                )
              }
            )}
          </ul>

          {selectedTab === "Featured" ? (
            <FeaturedOffers
              featuredOffersData={featuredOffersData}
              seriesYears={seriesYears}
              filtered={filtered}
              setFiltered={setFiltered}
            />
          ) : selectedTab === "Coupons" ? (
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{
                opacity: 1,
              }}
              transition={{ duration: 0.75 }}
            >
              <CouponsSection coupons={activeCoupons} />
              <DealersNearYou
                type="default"
                dealerCtaText="Schedule Service"
                dealerCtaLink="scheduleService"
                ctaAnalyticsId="schedule service"
              />
            </motion.div>
          ) : (
            <OffersTab
              offersData={offersData}
              seriesYears={seriesYears}
              filtered={filtered}
              setFiltered={setFiltered}
              selectedTabTitle={selectedTabTitle}
            />
          )}
        </nav>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query OffersPageQuery($id: String) {
    page: sanityPageOffers(id: { eq: $id }) {
      ...OfferPageData
      title
      seo {
        keywords
        schema
        description
        canonicalPath
      }
      disclaimers {
        code
        disclaimer
      }
    }
    offers: allSanityOffer(filter: { isExpiredAtBuildTime: { eq: false } }) {
      group(field: series___name) {
        fieldValue
        totalCount
        group(field: year) {
          fieldValue
          nodes {
            ...Offer
            series {
              name
              slug
            }
          }
        }
      }
    }
  }
`

/**
 * Offers page types
 */

type OfferPageCarouselItem = {
  _key: string
  desktopImage: imageWithAlt
  desktopVehicle: imageWithAlt
  mobileImage: imageWithAlt
  mobileVehicle: imageWithAlt
  offerType: string
  vehicleRef: {
    year: number
    series: Series
    models: Model[]
  }
}

export type OfferGroup = Offer & {
  series: {
    name: string
  }
}

type OffersPageProps = {
  data: {
    page: {
      featuredVehicles: {
        models: Model[]
        additionalSeries: {
          name: string
        }
      }[]
      vehicleFamilies: {
        familyRef: Family[]
      }
      hero: {
        _type: string
        carouselItems: OfferPageCarouselItem[]
      }
      title: string
      seo: {
        keywords: string
        schema: string
        description: string
        canonicalPath: string
      }
      coupons: { coupons: { nodes: Coupon[] } }
    }
    offers: {
      group: {
        fieldValue: string
        totalCount: string
        group: Array<{
          fieldValue: string
          nodes: OfferGroup[]
        }>
      }
    }
  }
  location: {
    href: string
  }
}

type featuredOffers = {
  key: string
  image: IGatsbyImageData
  name: string
  msrp: number
  mpgCity: number
  mpgHighway: number
  offers: {
    newest: Offer[]
    secondNewest: Offer[]
    availableOffers: number
  }
}

type featuredOffersModel = {
  model: {
    series: Series
    _id: string
    jellybean: {
      asset: {
        gatsbyImageData: IGatsbyImageData
      }
    }
  }
}

export default OffersPage
