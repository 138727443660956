import React, { useContext, useState } from "react"
import tw from "twin.macro"
import VehicleOffersGroup from "../VehicleOffersGroup/VehicleOffersGroup"
import { OffersTabProps } from "./OffersTab.d"
import { VehicleOffersGroupProps } from "../VehicleOffersGroup/VehicleOffersGroup.d"
import { FeaturedOffersData } from "../FeaturedOffers/FeaturedOffers.d"
import { LanguageContext } from "../../../../contexts/Language"
import FamilySeriesTabs from "../FamilySeriesTabs/FamilySeriesTabs"

const OffersTab: React.FC<OffersTabProps> = ({
  offersData,
  seriesYears,
  filtered,
  setFiltered,
  selectedTabTitle,
}) => {
  const [activeFamily, setActiveFamily] = useState(0)
  const { _ } = useContext(LanguageContext)

  const offerCounts =
    filtered.length === 0
      ? offersData[activeFamily].data // If no filters selected, render total offers count
          .map((node: FeaturedOffersData) => {
            if (node?.offers?.availableOffers) {
              return node.offers.availableOffers
            } else {
              return null
            }
          })
          ?.filter(availableOffersCount => availableOffersCount)
      : // Pull all selected offers lengths then return the sum
        filtered
          ?.map((node: FeaturedOffersData) => {
            if (node?.offers?.availableOffers) {
              return node.offers.availableOffers
            } else {
              return null
            }
          })
          ?.filter(availableOffersCount => availableOffersCount)

  const offersCount =
    offerCounts?.length > 0 ? offerCounts?.reduce((a, b) => a + b) : 0

  return (
    <section css={[tw`w-full`]}>
      <section css={[tw`w-full shadow-4`]}>
        <FamilySeriesTabs
          families={offersData}
          filtered={filtered}
          setFiltered={setFiltered}
          activeFamily={activeFamily}
          setActiveFamily={setActiveFamily}
        />
      </section>

      <section
        css={[tw`flex flex-col items-center pb-14`]}
        aria-label="Offers Tab Section"
      >
        <div css={[tw`max-w-7xl w-full`]}>
          <div id="offersSection" css={[tw`w-full pt-11 pb-4 px-10 lg:(px-0)`]}>
            <span css={[tw`font-semibold`]}>{offersCount}</span> {_("Offers")}
          </div>
        </div>
        {filtered.length === 0
          ? offersData[activeFamily].data.map(
              (node: VehicleOffersGroupProps, i: number) => (
                <VehicleOffersGroup
                  {...node}
                  filtered={filtered}
                  activeFamily={activeFamily}
                  seriesYears={seriesYears}
                  key={node?.key}
                  index={i}
                  selectedTabTitle={selectedTabTitle}
                />
              )
            )
          : filtered.map((node: VehicleOffersGroupProps, i: number) => (
              <VehicleOffersGroup
                {...node}
                filtered={filtered}
                activeFamily={activeFamily}
                seriesYears={seriesYears}
                key={node?.key}
                index={i}
                selectedTabTitle={selectedTabTitle}
              />
            ))}
      </section>
    </section>
  )
}

export default OffersTab
